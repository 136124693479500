<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID Transaccion, ID Familia" v-model="filterForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          @click.stop.prevent="handleClean"
          size="mini"
          class="margin-left-10"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col class="grid-content" style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="24">
          <el-select v-model="filterForm.status" clearable v-on:change="handleSearchFilter()" size="large" placeholder="Estatus">
            <el-option
              v-for="itemL in status"
              :key="itemL.status"
              :label="itemL.status"
              :value="itemL.status">
            </el-option>
          </el-select>
      </el-col>
      <el-col class="grid-content" align="center" :lg="16" :md="16" :sm="14" :xs="24">
        <span class=" margin-filter">Registros por fecha</span>
        <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="small"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
      </el-col>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" style="display: flex; justify-content: flex-end">
        <el-row type="flex" justify="end">
          <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
          </el-pagination>
        </el-row>
      </el-col>
    </el-row>
    <!-- <el-col :span="12" :offset="0">
      <el-tag
        size="medium"
        effect="plain"
        :key="idUser"
        v-for="idUser in idUserTags"
        closable
        :disable-transitions="false"
        @close="handleClose(idUser)">
        {{idUser}}
      </el-tag>
    </el-col> -->
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      highlight-current-row
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
      <el-table-column label="ID Transacción" width="120" fixed align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.idTransactionJR }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus"  width="120" align="center" >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 'Error'">
            <el-tag size="mini" type="danger">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else-if="scope.row.status == 'Invoiced'">
            <el-tag size="mini" type="success">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else-if="scope.row.status == 'Sent to invoicing'">
            <el-tag size="mini" type="warning">{{ scope.row.status }}</el-tag>
          </div>
          <div v-else>
            <el-tag size="mini">{{ scope.row.status }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Folio Fiscal" width="100" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.folio_fiscal">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{scope.row.folio_fiscal}}</div>
              <span>{{ scope.row.folio_fiscal.toString().substr(-4) }}</span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Folio" width="100" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.folio }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ID Familia" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.idFamilyJR }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre de Familia"  width="200" align="center" >
        <template slot-scope="scope">
          {{ scope.row.familyName }}
        </template>
      </el-table-column>
      <el-table-column label="Ubicación" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" sortable label="Fecha de Pago"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.datePaymentJR }}
        </template>
      </el-table-column>
      <el-table-column label="Tipo"  width="90" align="center" >
        <template slot-scope="scope">
          {{ scope.row.type }}
        </template>
      </el-table-column>
      <el-table-column label="Concepto"  width="150" align="center" >
        <template slot-scope="scope">
          {{ scope.row.subType }}
        </template>
      </el-table-column>
      <el-table-column label="Cantidad Pagada"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.amount }}
        </template>
      </el-table-column>
      <el-table-column label="Método de Pago"  width="120" align="center" >
        <template slot-scope="scope">
          {{ scope.row.paymentMethod }}
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro"  width="130" align="center" >
        <template slot-scope="scope">
          {{ scope.row.created_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column label="Error"  width="170" align="center" >
        <template slot-scope="scope">
          {{ scope.row.error }}
        </template>
      </el-table-column>
      <el-table-column label="Operaciones" fixed="right" width="170" align="center" >
        <template slot-scope="scope">
          <div v-if="scope.row.status == 'Invoiced'">
            <el-button size="mini" type="success" :loading="scope.row.loadingBtnDownloadPDF" @click.stop.prevent="handleDownloadPDF(scope.row)" round>PDF</el-button>
            <el-button size="mini" type="success" :loading="scope.row.loadingBtnDownloadXML" @click.stop.prevent="handleDownloadXML(scope.row)" round>XML</el-button>
          </div>
          <div v-else-if="scope.row.status == 'Error'">
            <el-button size="mini" type="danger" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleFix(scope.row)" round>Corregir</el-button>
          </div>
          <div v-else-if="scope.row.status == 'Sent to invoicing'">
            <el-button size="mini" type="warning" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleVerify(scope.row)" round>Verificar facturación</el-button>
          </div>
          <div v-else>
            <el-button size="mini" type="primary" :loading="scope.row.loadingBtnInvoice" @click.stop.prevent="handleInvoice(scope.row)" round>Enviar a facturación</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
    <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
      :total="parseInt(totalPages)">
    </el-pagination>
  </el-row>
  </div>
</template>

<script>
import { search, getPage, getStatus, sendToInvoice, verifyInvoice, downloadPDF, downloadXML } from '@/api/transactionsJR'

export default {
  data () {
    return {
      filterForm: {
        query: '',
        status: '',
        initDate: '',
        endDate: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      value: '',
      status: [],
      filterDateRegisterRange: '',
      sizePerPage: 25
    }
  },
  mounted () {
    this.handleSearchFilter()
    this.getStatusJR()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.filterForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async getStatusJR () {
      await getStatus()
        .then((response) => {
          this.status = response.data
          // console.log(response)
        })
        .catch(this.responseCatch)
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.filterForm.initDate = this.filterDateRegisterRange[0]
        this.filterForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.initDate = ''
        this.filterForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.filterForm = {
        query: '',
        status: '',
        initDate: '',
        endDate: ''
      }
      this.handleSearchFilter()
    },
    async handleInvoice (row) {
      this.$set(row, 'loadingBtnInvoice', true)
      await sendToInvoice(row._id)
        .then((response) => {
          if (response.data === true) {
            this.$message({
              showClose: true,
              message: 'Transacción enviada a facturar exitosamente',
              type: 'success'
            })
          } else {
            this.$message.error('Oops, algo a salido mal.')
          }
        })
        .catch(this.responseCatch, this.reload())
        .finally(() => (this.$set(row, 'loadingBtnInvoice', false)))
    },
    reload () {
      setTimeout(() => { this.handleCurrentChange(this.currentPage) }, 1000)
    },
    async handleVerify (row) {
      this.$set(row, 'loadingBtnInvoice', true)
      await verifyInvoice(row._id)
        .then((response) => {
          if (response.data === false) {
            this.$message({
              showClose: true,
              message: 'Ticket todavia no ha sido facturado',
              type: 'warning'
            })
          } else {
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: '<strong>Ticket facturado.</strong><br>Folio Fiscal:' + response.data.folio_fiscal,
              type: 'success'
            })
            row.status = 'Invoiced'
            row.folio_fiscal = response.data.folio_fiscal
            row.folio = response.data.folio
          }
        })
        .catch(this.responseCatch)
        .finally(() => (this.$set(row, 'loadingBtnInvoice', false)))
    },
    async handleDownloadPDF (row) {
      this.$set(row, 'loadingBtnDownloadPDF', true)
      await downloadPDF(row._id)
        .then((response) => {
          console.log(response)
          const link = document.createElement('a')
          link.href = response.data.file
          link.download = response.data.name
          link.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => (this.$set(row, 'loadingBtnDownloadPDF', false)))
    },
    async handleDownloadXML (row) {
      this.$set(row, 'loadingBtnDownloadXML', true)
      await downloadXML(row._id)
        .then((response) => {
          console.log(response)
          const link = document.createElement('a')
          link.href = response.data.file
          link.download = response.data.name
          link.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => (this.$set(row, 'loadingBtnDownloadXML', false)))
    },
    handleFix (row) {
      this.$router.push({ name: 'shopify.product.fix', query: { id: row._id } })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
