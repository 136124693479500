import request from '@/utils/request'

export function search (data) {
  return request({
    url: '/jackrabbit/transactions/search',
    method: 'post',
    data
  })
}

export function getPage (page, noitems, data) {
  return request({
    url: '/jackrabbit/transactions/search?page=' + page + '&item=' + noitems,
    method: 'post',
    data
  })
}

export function getStatus () {
  return request({
    url: '/jackrabbit/transactions/status',
    method: 'get'
  })
}

export function sendToInvoice (id) {
  return request({
    url: '/jackrabbit/transactions/invoice/' + id,
    method: 'get'
  })
}

export function verifyInvoice (id) {
  return request({
    url: '/jackrabbit/transactions/invoice/verify/' + id,
    method: 'get'
  })
}
export function downloadPDF (id) {
  return request({
    url: '/jackrabbit/transactions/invoice/download/pdf/' + id,
    method: 'get'
  })
}

export function downloadXML (id) {
  return request({
    url: '/jackrabbit/transactions/invoice/download/xml/' + id,
    method: 'get'
  })
}
